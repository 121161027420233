import cn from 'classnames';
import type { FC, ReactNode } from 'react';
import { memo, useMemo } from 'react';

import { Button } from '@sravni/react-design-system';

import styles from './styles.module.scss';

interface IToastBodyComponentProps {
    className?: string;
    title?: ReactNode;
    icon?: ReactNode;
    description?: ReactNode;
    linkText?: ReactNode;
    linkHref?: string;
    buttonText?: ReactNode;
    buttonOnClick?: () => void;
}

export const ToastBodyComponent: FC<IToastBodyComponentProps> = memo(
    ({ className, title, icon, children, description, linkText, linkHref, buttonText, buttonOnClick }) => {
        const renderContent = useMemo(() => {
            if (![children, description, linkText, linkHref, buttonText, buttonOnClick].some(Boolean)) {
                return null;
            }
            return (
                <div className={styles.content}>
                    {children || (
                        <>
                            {description && description}
                            {linkText && linkHref && (
                                <a className={styles.link} target="_blank" href={linkHref} rel="noreferrer">
                                    {linkText}
                                </a>
                            )}
                            {buttonText && buttonOnClick && (
                                <Button className={styles.button} variant="primary" onClick={buttonOnClick}>
                                    {buttonText}
                                </Button>
                            )}
                        </>
                    )}
                </div>
            );
        }, [description, linkText, linkHref, buttonText, buttonOnClick, children]);

        return (
            <div className={cn(styles.toast_body, className)}>
                <div className={styles.title}>
                    {icon && icon}
                    {title}
                </div>
                {renderContent}
            </div>
        );
    },
);
