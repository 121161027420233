import { useSelector } from 'react-redux';
import { createAction, createReducer } from 'redux-act';

import type { MicrocreditDeal } from '@src/@types/microcreditDeal';
import type { IGlobalState } from '@src/reducers/index';

import DataStatus from '../enums/DataStatus';

type IState = {
    data: MicrocreditDeal[];
    status: DataStatus;
};

const initialState: IState = {
    data: [],
    status: DataStatus.Default,
};

export const setMicrocreditUserDeals = createAction<IState>('deals/set');

export const microcreditUserDealsReducer = createReducer<IState>({}, initialState).on(
    setMicrocreditUserDeals,
    (_state, payload) => payload,
);

const selectMicrocreditUserDealsState = (state: IGlobalState): MicrocreditDeal[] => state.microcreditUserDeals.data;

export const useMicrocreditUserDealsState = () => useSelector(selectMicrocreditUserDealsState);
