import { useSelector } from 'react-redux';
import { createAction, createReducer } from 'redux-act';

import type { DialogInitialType } from '@src/@types/authDialog';

import type { IGlobalState } from '.';

interface IState {
    isOpen: boolean;
    dialogInitialType: DialogInitialType;
}

const initialState: IState = {
    isOpen: false,
    dialogInitialType: 'firstFreeLoan',
};

export const closeAuthDialog = createAction('authDialog/close');
export const openAuthDialog = createAction<{ dialogInitialType: DialogInitialType }>('authDialog/open');

export const authDialogReducer = createReducer<IState>({}, initialState)
    .on(closeAuthDialog, (state) => ({
        ...state,
        isOpen: false,
    }))
    .on(openAuthDialog, (state, payload) => ({
        ...state,
        isOpen: true,
        ...payload,
    }));

const getAuthDialog = (state: IGlobalState) => state.authDialog;

export function useAuthDialog(): IState {
    return useSelector<IGlobalState, IState>(getAuthDialog);
}
