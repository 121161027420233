import getConfig from 'next/config';

import type * as Microcredits from '@src/@types/microcredits';

import client from '../utils/client';

export const fetchProduct = async (
    organizationAlias: Microcredits.Card.IReq['organizationAlias'],
    alias: Microcredits.Card.IReq['alias'],
): Promise<Microcredits.Card.IRes> => {
    const { data } = await client.get(
        `${getConfig().publicRuntimeConfig.publicUrl}/v1/microCredit/card/${organizationAlias}/${alias}/`,
        { timeout: 3000 },
    );

    return data;
};

export const fetchProductAdvertising = async (id: string, query: { location: string }) => {
    const { data } = await client.get(
        `${getConfig().publicRuntimeConfig.publicUrl}/v1/microCredit/${id}/advertising/`,
        {
            params: query,
        },
    );

    return data;
};
